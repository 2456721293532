import React, { useRef, useState } from "react";
// import emailjs, { send } from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "./Tooltip";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = ({ classicHeader, darkTheme }) => {
  // const form = useRef();
  const [sendingMail, setSendingMail] = useState(false);

  const [nameForm, setNameForm] = useState("");
  const [emailForm, setEmailForm] = useState("");
  const [messageForm, setMessageForm] = useState("");

  const [validationsFromServer, setValidationsFromServer] = useState([]);
  const [, setValidationsMessage] = useState("");
  const reRef = useRef();

  const handleName = (event) => {
    //console.log(event.target.value);
    setNameForm(event.target.value);
  };

  const handleEmail = (event) => {
    //console.log(event.target.value);
    setEmailForm(event.target.value);
  };

  const handleMessage = (event) => {
    //console.log(event.target.value);
    setMessageForm(event.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setSendingMail(true);

    const captchaToken = reRef.current.getValue();
    reRef.current.reset();
    axios
      .post("/contactme", {
        name: nameForm,
        email: emailForm,
        message: messageForm,
        captchaToken: captchaToken,
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          document.getElementById("contact-form").reset();
          toast.success("Message sent successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          setSendingMail(false);
          setValidationsFromServer([]);
          setNameForm("");
          setEmailForm("");
          setMessageForm("");
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setValidationsFromServer(error.response.data.errors);
          setValidationsMessage(error.response.data.message);
          setSendingMail(false);
        } else if (error.response.status === 500) {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: darkTheme ? "dark" : "light",
          });
          setSendingMail(false);
        }
      });

    // emailjs
    //   .sendForm(
    //     "service_i86k3ms",
    //     "template_si6cin9",
    //     form.current,
    //     "c9HsDgGF0tvWyVnAL"
    //   )
    //   .then(
    //     (result) => {
    //       document.getElementById("contact-form").reset();
    //       toast.success("Message sent successfully!", {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: darkTheme ? "dark" : "light",
    //       });
    //       console.log(result.text);
    //       setSendingMail(false);
    //     },
    //     (error) => {
    //       toast.error("Something went wrong!", {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: darkTheme ? "dark" : "light",
    //       });
    //       console.log(error.text);
    //       setSendingMail(false);
    //     }
    //   );
  };

  const foundInvalidMessage = (field) => {
    let found = "";
    let foundMessage = validationsFromServer?.find((e) => e.property === field);
    if (foundMessage !== undefined) {
      found =
        foundMessage.constraints[Object.keys(foundMessage.constraints)[0]];
    }
    return found;
  };

  return (
    <section
      id="contact"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Contact
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Get in Touch
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* contact details */}
          <div className="col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start">
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Address
            </h2>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              Hermosillo, SON, 83305
              <br />
            </p>
            {/* <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-phone" />
              </span>
              (060) 444 434 444
            </p>
            <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-fax" />
              </span>
              (060) 555 545 555
            </p>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" />
              </span>
              chat@simone.com
            </p> */}
            <h2
              className={
                "mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")
              }
            >
              Follow Me
            </h2>
            <ul
              className={
                "social-icons justify-content-center justify-content-md-start " +
                (darkTheme ? "social-icons-muted" : "")
              }
            >
              {/* <li className="social-icons-dribbble">
                <Tooltip text="Dribbble" placement="top">
                  <a
                    href="http://www.dribbble.com/harnishdesign/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-dribbble" />
                  </a>
                </Tooltip>
              </li> */}
              <li className="social-icons-linkedin">
                <Tooltip text="LinkedIn" placement="top">
                  <a
                    href="https://www.linkedin.com/in/guillermo-iniguez/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </Tooltip>
              </li>
              <li className="social-icons-twitter">
                <Tooltip text="Twitter" placement="top">
                  <a
                    href="https://twitter.com/guillermo_i"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </Tooltip>
              </li>
            </ul>
          </div>
          {/* contact form */}
          <div className="col-md-8 col-xl-9 order-0 order-md-1">
            <h2
              className={
                "mb-3 text-5 text-uppercase text-center text-md-start " +
                (darkTheme ? "text-white" : "")
              }
            >
              Send me a note
            </h2>
            {/* <form
              className={darkTheme ? "form-dark" : ""}
              id="contact-form"
              action="php/mail.php"
              method="post"
              ref={form}
              onSubmit={sendEmail}
            > */}
            <form
              className={darkTheme ? "form-dark" : ""}
              id="contact-form"
              onSubmit={(event) => {
                sendEmail(event);
              }}
            >
              <div className="row g-4">
                <div className="col-xl-6">
                  <input
                    name="user_name"
                    type="text"
                    className={
                      validationsFromServer?.find((e) => e.property === "name")
                        ? "form-control invalid"
                        : "form-control"
                    }
                    placeholder="Name"
                    onInput={(event) => handleName(event)}
                    // onChange={(event) => handleName(event)}
                  />
                  <div style={{ color: "red" }}>
                    {foundInvalidMessage("name")}
                  </div>
                </div>
                <div className="col-xl-6">
                  <input
                    name="user_email"
                    type="email"
                    className={
                      validationsFromServer?.find((e) => e.property === "email")
                        ? "form-control invalid"
                        : "form-control"
                    }
                    placeholder="Email"
                    onInput={(event) => handleEmail(event)}
                    //onChange={(event) => handleEmail(event)}
                  />
                  <div style={{ color: "red" }}>
                    {foundInvalidMessage("email")}
                  </div>
                </div>
                <div className="col">
                  <textarea
                    name="message"
                    className={
                      validationsFromServer?.find(
                        (e) => e.property === "message"
                      )
                        ? "form-control invalid"
                        : "form-control"
                    }
                    rows={5}
                    placeholder="Tell me more about your needs..."
                    defaultValue={""}
                    onInput={(event) => handleMessage(event)}
                    //onChange={(event) => handleMessage(event)}
                  />
                  <div style={{ color: "red" }}>
                    {foundInvalidMessage("message")}
                  </div>
                </div>
                <ReCAPTCHA
                  ref={reRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  size="normal"
                  theme="dark"
                />
                <div style={{ color: "red", marginTop: "5px" }}>
                  {foundInvalidMessage("captchaToken")}
                </div>
              </div>
              <p className="text-center mt-4 mb-0">
                <button
                  id="submit-btn"
                  className="btn btn-primary rounded-pill d-inline-flex"
                  type="submit"
                >
                  {sendingMail ? (
                    <>
                      <span
                        role="status"
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm align-self-center me-2"
                      ></span>
                      Sending...
                    </>
                  ) : (
                    <>Send Message</>
                  )}
                </button>
              </p>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
