import React from "react";
// import resumeFile from "../documents/resume.pdf";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "1998 - 2003",
      title: "Engineer Computer Science",
      place: "Instituto Tecnológico de Hermosillo",
      desc: "",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2023 - 2024",
      title: "Freelance Software Engineer.",
      place: "Vado, Hermosillo, Sonora",
      desc: [
        "Collaborate with Vado team to build Node.js Express backend to hire remote developer talent process.",
        "Collaborate to create API TypeDI inversion of control backend integrating typeORM PostgreSQL database.",
        "DNS, SSL certification configuration on DigitalOcean service managed infrastructure (PaaS).",
        "...",
      ],
    },
    {
      yearRange: "2023 - 2023",
      title: "Freelance Software Engineer",
      place: "InstitutoCertified, Hermosillo, Sonora",
      desc: [
        "Design, develop and deploy a React.js web application for English language certifications platform.",
        "Implement Node.js Express role-based access for administrators, examiners and candidates users. ",
        "Create import features for users, certifications questions from bulk XLSX files and persisting data in MongoDB.",
        "Manage certification statuses, create role-based dashboard, generate statistical reports and QR code tests results access.",
        "Candidate certification tests (dynamic and random category questions grammar, listening, writing, speaking).",
        "DNS, SSL certification and Apache configuration for secure deployment on AWS Lightsail instance.",
        "...",
      ],
    },
    {
      yearRange: "2019 - 2022",
      title: "Lead Software Engineer",
      place: "PipingRock, Hermosillo, Sonora",
      desc: [
        "Migrate from Twig PHP Symfony front-end website to modern Next.js framework website.",
        "Create PHP Symfony API endpoints for new requirements features and reuse PHP API endpoints for existent features.",
        "Integrate OAuth2 google and facebook social login.",
        "Implement new features migration (carousel, FAQ, login, cart, catalogs) from figma design and maintain delivered features.",
        "Integrate management structure from third-party headless contentstack to enable marketing to modify website content.",
        "Coordinate scheduled releases for migration. Walking Through/Demos for delivered new features. Code review of team´s PR.",
        "Split, grooming task features following scrum methodology with the team.",
        "Work closely with the project manager to define estimations and release dates.",
        "Provided guidance and direction to less experienced staff in resolution of highly complex technical problems.",
        "Working collaboratively with stakeholders to resolve technical roadblocks.",
        "Researching third-party integrations like Content Management System, Optimizely, Cloudinary, Google Tag Manager.",
        "...",
      ],
    },
    {
      yearRange: "2008 - 2019",
      title: "Mid Software Engineer",
      place: "PipingRock, Hermosillo, Sonora",
      desc: [
        "Analyzing current health-products PHP Symfony monolithic architecture shipment/fulfillment logic process.",
        "Breakdown new fulfillment Business Requirements Document into tasks.",
        "Defining fulfillment interfaces, classes for microservice to follow some SOLID principles.",
        "Develop and implement new fulfillment logic following some design patterns like singleton, decorator, abstract factory and dependency injection.",
        "Design MySQL relationship database tables structure and Doctrine ORM Entities for microservice.",
        "Integrate communication between fulfillment microservice and inventory system using Consumer/Producers RabbitMQ.",
        "Create and expose fulfillment RESTful API endpoints using API Platform.",
        "Implement AWS Fargate and remove cron scheduling tasks for some of the background PHP processes.",
        "Implement decision logic communicating with other microservices for defining the warehouse where the package fulfills.",
        "...",
      ],
    },
    {
      yearRange: "2008 - 2019",
      title: "Software Engineer",
      place: "Intersel, Hermosillo, Sonora",
      desc: [
        "Developing recording phone calls and transcription audios in .NET Core RESTful technology.",
        "Audio transcription with Amazon Transcribe, S3 bucket and threading transcription request architecture",
        "Develop admin and customer user website portal with call accounting information using C# .NET Framework, MVC, SQL, Bootstrap, jquery, Sencha ExtJs technologies.",
        "Develop front-end user dashboard for desktop main product of the company.",
        "Create a reporting cloud module for calling accounting customers information using Microsoft Reporting Services Technology.",
        "Create an application with CISCO integration, which displays the company calls usage statistics on the device screen.",
        "Collaborate with team designing, developing and releasing most of the stand alone version call accounting products.",
        "...",
      ],
    },
  ];

  const skills = [
    {
      name: "Nodejs",
      percent: 75,
    },
    {
      name: "JavaScript",
      percent: 70,
    },
    {
      name: "React JS",
      percent: 70,
    },
    {
      name: "Nextjs",
      percent: 60,
    },
    {
      name: "MERN stack",
      percent: 75,
    },
    {
      name: "HTML/CSS",
      percent: 85,
    },
    {
      name: "Restful Services(Express, .NET, .NET Core, API Platform)",
      percent: 70,
    },
    {
      name: "AWS (Lambda, S3 Bucket, CloudFront, etc)",
      percent: 40,
    },

    {
      name: "Deploy environments (AWS Lightsail, DigitalOcean, Vercel)",
      percent: 60,
    },
    {
      name: "Jira, Bitbucket, GitHub, Confluence (Atlassian)",
      percent: 80,
    },
    {
      name: "Development Lifecycles",
      percent: 80,
    },
    {
      name: "Microsoft Sql Server/MySql",
      percent: 80,
    },
    {
      name: "Bootstrap",
      percent: 80,
    },
    {
      name: "Agile Methodology (Scrum)",
      percent: 80,
    },
    {
      name: "C#",
      percent: 80,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-4">
          {/* My Education */}
          <div className="col-md-5">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-7">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-3 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc.map((valueDesc, index) => {
                      return (
                        <span
                          key={index}
                          className={
                            "mb-0 " + (darkTheme ? "text-white-50" : "")
                          }
                        >
                          <br />
                          {`${valueDesc}`}
                        </span>
                      );
                    })}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        {/* <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default Resume;
