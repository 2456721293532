import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Aurora Velez",
      position: "Software QA Lead",
      linkedin: "https://www.linkedin.com/in/auroravelez/",
      src: "images/testimonial/profile-aurora.jpg",
      desc: "Guillermo is dedicated and always focused on finding the best solutions to the needs of his clients. He is characterized by his responsibility to meet the agreed scopes without neglecting the quality of software development and an excellent ability to analyze every detail of the problems.",
      rating: 5,
    },
    {
      name: "David Rembao",
      position: "Lead Software Engineer",
      linkedin: "https://www.linkedin.com/in/drembao/",
      src: "images/testimonial/profile-david.jpg",
      desc: "I have known Guillermo Iniguez for more than 10 years and I can highly recommend him as an excellent professional in the software development industry. He is a honest, responsible, intelligent person, capable of solving any problem solutions.",
      rating: 5,
    },
    {
      name: "Omar Marin",
      position: "Cloud Engineer",
      linkedin: "https://www.linkedin.com/in/ruben-omar-marin-sainz-56614785/",
      src: "images/testimonial/profile-omar.jpg",
      desc: "Guillermo and I we have been participated together in several projects throughout our careers and he has always distiguish for his dedication to achieve the objectives with excellence that make him a trustworthy person.",
      rating: 5,
    },
    {
      name: "Ricardo Campa",
      position: "Tech. Admin. at CJF",
      linkedin:
        "https://www.linkedin.com/in/jos%C3%A9-ricardo-campa-l%C3%B3pez-97997b2a/",
      src: "images/testimonial/profile-ricardo.jpg",
      desc: "In the 29 years of knowing Guillermo, I have realized that he is a person of surprising values ​​and has a higher than normal level of commitment; His problem-solving ability is fast and effective. He has surprising order and control, he is a great example to follow.",
      rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Testimonial
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Colleagues Speak
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    />
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {value.position}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>

                  <div>
                    <span className="text-2">
                      {[...Array(value.rating)].map((value, i) => (
                        <i className="fas fa-star text-warning" key={i} />
                      ))}
                    </span>

                    <span style={{ float: "right" }}>
                      <a
                        href={value.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
